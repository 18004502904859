import React from "react";
import * as moment from "moment-timezone";

class Countdown extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            time: null
        }
    }

    componentDidMount() {
        this.updateTime();
        this.timer = setInterval(this.updateTime.bind(this), 1000);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.date !== this.props.date) {
            this.updateTime();
        }
    }

    componentWillUnmount() {
        if(this.timer) {
            clearInterval(this.timer);
        }
    }

    getMoment() {
        return moment.tz(this.props.date, "Europe/Amsterdam");
    }

    updateTime() {
        let time = Math.ceil(this.getMoment().diff(moment()) / 1000);
        this.setState({ time });
    }

    render() {
        const time = Math.max(this.state.time, 0);

        if(time === 0) {
            return "We spelen nu!"
        }

        const days = Math.floor(time / 60 / 60 / 24);
        const hours = Math.floor((time - days * 60 * 60 * 24) / 60 / 60);
        const minutes = Math.floor((time - days * 60 * 60 * 24 - hours * 60 * 60) / 60);
        const seconds = Math.floor(time - days * 60 * 60 * 24 - hours * 60 * 60 - minutes * 60);

        const textDays = days === 1 ? `${days} dag` : `${days} dagen`
        const textHours = hours < 10 ? "0" + hours : hours;
        const textMinutes = minutes < 10 ? "0" + minutes : minutes;
        const textSeconds = seconds < 10 ? "0" + seconds : seconds;

        return (
            <React.Fragment>
                { days > 0 && (
                    <React.Fragment>
                        <div className="d-inline-block d-md-none">
                            { textDays }
                            <br/>
                        </div>
                        <div className="d-none d-md-inline-block">
                            { textDays }
                            <small><i className="fal fa-minus mx-3"/></small>
                        </div>
                    </React.Fragment>
                )}
                <div className="d-block d-md-inline-block">
                    { textHours + ":" + textMinutes + ":" + textSeconds }
                </div>
            </React.Fragment>
        );
    }
}

export default Countdown;
