import moment from "moment";

const allConcerts = [
    {
        startDate: "2024-06-22 12:00",
        endDate: "2024-06-22 23:00",
        title: "N.F.L.S. Festival",
        address: "Katwijk"
    },
    {
        startDate: "2024-06-14 20:30",
        endDate: "2024-06-15 01:00",
        title: "Clubtour 2024 - WPC Nederland 3",
        address: "Lange Spruit 84, 2291 LC Wateringen",
    },
    {
        startDate: "2024-05-25 21:00",
        endDate: "2024-05-26 01:00",
        title: "Brandweer 's-Gravenzande",
        address: "Besloten feest"
    },
    {
        startDate: "2023-09-02 21:00",
        endDate: "2023-09-02 23:59",
        title: "Podium Westland",
        address: "Havenplein 1, 2671 GT Naaldwijk",
        description: "Dit keer zijn we uitgenodigd om bij Podium Westland te spelen buiten bij de Slimerick. "
            + "Reserveer alvast deze datum want wij zullen Naaldwijk eens laten horen wie wij zijn."
    },
    {
        startDate: "2023-08-19 17:00",
        endDate: "2023-08-19 18:00",
        title: "Feestweek Naaldwijk",
        address: "Molenstraat 58, 2671 EZ Naaldwijk",
    },
    {
        startDate: "2023-05-05 21:30",
        endDate: "2023-05-06 00:30",
        title: "Clubtour 2023 - WPC Nederland 3",
        address: "Lange Spruit 84, 2291 LC Wateringen",
        description: "ARE YOU READY? Want vrijdag 5 mei is het zover. Niet 1 maar 2 van je favoriete bands samen op het podium."
            + "🤩 Dat is dubbel zo hard dansen en dubbel zo hard zingen 💃🏽🕺🏽 Wij, Second To Last en Distracted, verzorgen samen de hele avond."
            + "We hebben zóveel leuke nummers en dansjes voor jullie in petto! 🧢 Wij zouden zeggen koop nu je kaartje want het is ook 2x zo snel uitverkocht!",
        ticketsUrl: "https://www.nederlanddrie.nl/programma/?s=05-041-2023-second-to-last-distracted-vr"
    },
    {
        startDate: "2023-04-28 22:20",
        endDate: "2023-04-28 23:00",
        title: "Top Westland - WPC Nederland 3",
        address: "Lange Spruit 84, 2291 LC Wateringen",
        ticketsUrl: "https://www.nederlanddrie.nl/programma/?s=28-041-2023-top-westland-finale-vr"
    },
    {
        startDate: "2022-11-26 21:00",
        endDate: "2022-11-27 00:45",
        title: "Trompestampe 2022",
        address: "Lange Spruit 84, 2291 LC Wateringen",
        description: "Jahoor!! Dit jaar staan we weer op Trompestampe in WPC Nederland 3. Koop nog snel je kaarten want dit wordt episch. En als kers op de taart sluiten wij dit jaar af!",
        ticketsUrl: "https://www.nederlanddrie.nl/programma/?s=26-041-2022-trompestampe-2022"
    },
    {
        startDate: "2022-09-03 12:45",
        endDate: "2022-09-03 13:30",
        title: "Podium Westland",
        address: "Havenplein 1, 2671 GT Naaldwijk",
        description: "Dit keer zijn we uitgenodigd om bij Podium Westland te spelen buiten bij de Slimerick. "
            + "Reserveer alvast deze datum want wij zullen Naaldwijk eens laten horen wie wij zijn."
    },
    {
        startDate: "2022-07-02 20:45",
        endDate: "2022-07-02 21:33",
        title: "Optreden Muziekcentrum Westland (MCW)",
        address: "Verdilaan 2, Naaldwijk",
        description: "Op 2 juli hebben wij weer een optreden. Dit keer bij MCW in Naaldwijk. " +
            "We spelen dan voor een goed doel, dus er zal iets van een kleine toegangsprijs gevraagd worden."
    }
];

const sortedConcerts = allConcerts.sort((concert1, concert2) => {
    const date1 = moment(concert1.startDate);
    const date2 = moment(concert2.startDate);
    if(date1 > date2) return -1;
    if(date1 < date2) return 1;
    return 0;
})

export default sortedConcerts;
