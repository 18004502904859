import React, {
    useMemo
} from "react";
import moment from "moment";

import allConcerts from "./allConcerts";
import ConcertCard from "./ConcertCard";

function Concerts({ type = "upcoming" }) {
    const concerts = useMemo(() => {
        const now = moment();
        switch(type) {
            case "upcoming":
                return allConcerts.filter((concert) => {
                    const startDate = moment(concert.endDate);
                    return now < startDate;
                }).reverse();
            case "upcoming-home":
                const filterDate = now.clone().subtract(2, "days");
                return allConcerts.filter((concert) => {
                    const startDate = moment(concert.endDate);
                    return filterDate < startDate;
                }).reverse();
            case "past":
                return allConcerts.filter((concert) => {
                    const endDate = moment(concert.endDate);
                    return now > endDate;
                });
            default:
                return [];
        }
    }, [type]);

    if(concerts.length === 0) {
        return (
            <div className="text-center">
                { type === "upcoming" ? (
                    <h3>Op dit moment zijn er geen concerten gepland.</h3>
                ) : (
                    <h3>Kan geen concerten vinden.</h3>
                )}
            </div>
        );
    }

    return concerts.map((concert, index) => (
        <ConcertCard concert={ concert } key={ index }/>
    ));
}

export default React.memo(Concerts);
