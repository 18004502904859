import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ScrollToTop from "./hooks/ScrollToTop";

import Home from "./pages/Home";
import AllConcerts from "./pages/AllConcerts";
import Page404 from "./pages/Page404";

function App() {
    return (
        <Router>
            <ScrollToTop/>
            <Navbar/>
            <Routes>
                <Route path="/" element={ <Home/> }/>
                <Route path="/concerten" element={ <AllConcerts/> }/>
                <Route path="*" element={ <Page404/> }/>
            </Routes>
            <Footer/>
        </Router>
    );
}

export default App;
