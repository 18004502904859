import React from "react";
import {
    Helmet as ReactHelmet
} from "react-helmet";

function Helmet({ title = null, description = "Wij zijn Second To Last. Een 6 koppige pop-rock band uit het Westland. Ons repetoire bestaat uit een mix van covers en eigen nummers. Ons doel is altijd om ons publiek een onvergetelijke avond te bezorgen. Één om nog lang op terug te kijken." }) {
    return (
        <ReactHelmet>
            <title>
                { title === null ? "Second To Last Band" : (title + " - Second To Last Band") }
            </title>
            <meta name="description" content={ description } />
        </ReactHelmet>
    );
}

export default Helmet;
