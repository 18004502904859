import React, {
    useMemo
} from "react";
import moment from "moment";
import {
    Col,
    Row
} from "react-bootstrap";

import Countdown from "../Countdown";

function ConcertCard({ concert }) {
    const showCountdown = useMemo(() => {
        return moment() < moment(concert.endDate);
    }, [concert]);

    const startDate = moment(concert.startDate);
    const endDate = moment(concert.endDate);

    return (
        <div className="card card-dark mb-3">
            <div className="card-body">
                <Row>
                    <Col md={ 4 } lg={ 3 } className="text-center d-none d-md-block">
                        <h4>
                            { startDate.format("DD-MM-YYYY") }
                        </h4>
                        <p className="text-muted card-text">
                            { startDate.format("HH:mm") }
                            <span> - </span>
                            { endDate.format("HH:mm") }
                        </p>
                        { showCountdown && (
                            <p className="text-muted card-text">
                                <Countdown date={ concert.startDate }/>
                            </p>
                        )}
                    </Col>
                    <Col xs={ showCountdown ? 6 : 12 } className="text-center d-block d-md-none">
                        <h4>
                            { startDate.format("DD-MM-YYYY") }
                        </h4>
                        <p className="text-muted">
                            { startDate.format("HH:mm") }
                            <span> - </span>
                            { endDate.format("HH:mm") }
                        </p>
                    </Col>
                    { showCountdown && (
                        <Col xs={ 6 } className="text-center d-block d-md-none">
                            <p className="text-muted">
                                <Countdown date={ concert.startDate }/>
                            </p>
                        </Col>
                    )}
                    <Col>
                        <h5 className="card-title mb-0">
                            { concert.title }
                        </h5>
                        <p className="card-text text-muted">
                            { concert.address }
                        </p>
                        <p className="card-text">
                            { concert.description }
                        </p>
                        { concert.ticketsUrl && showCountdown && (
                            <a href={ concert.ticketsUrl } target="_blank" rel="noreferrer noopener" className="btn btn-primary">
                                Koop tickets
                            </a>
                        )}
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default React.memo(ConcertCard);
