import React, {
    useState
} from "react";
import {
    Link,
    matchPath,
    useLocation
} from "react-router-dom";
import {
    Container,
    Nav,
    Navbar as RBNavbar
} from "react-bootstrap";

function NavbarLink({to, title, hidden = false, onClick}) {
    const { pathname } = useLocation();
    const match = matchPath(to, pathname);
    let className = "nav-item" + (match ? " active" : "") + (hidden ? " d-none" : "");
    return (
        <li className={ className } data-toggle="collapse" data-target=".navbar-collapse.show">
            <Link to={ to } className="nav-link" onClick={ onClick }>
                { title }
            </Link>
        </li>
    );
}

function Navbar() {
    const [showCollapsed, setShowCollapsed] = useState(false);
    const collapse = () => {
        setShowCollapsed(false);
    }

    return (
        <React.Fragment>
            <RBNavbar bg="dark" variant="dark" expand="lg" fixed="top" expanded={ showCollapsed }>
                <Container>
                    <RBNavbar.Brand href="/">Second To Last</RBNavbar.Brand>
                    <RBNavbar.Toggle aria-controls="basic-navbar-nav" onClick={ () => setShowCollapsed(!showCollapsed) }/>
                    <RBNavbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <NavbarLink to="/" title="Home" onClick={ collapse }/>
                            <NavbarLink to="/concerten" title="Concerten" onClick={ collapse }/>
                        </Nav>
                    </RBNavbar.Collapse>
                </Container>
            </RBNavbar>
            <div style={{ height: "56px" }}/>
        </React.Fragment>
    )
}

export default React.memo(Navbar);
