import React from "react";
import {
    Container
} from "react-bootstrap";

import Concerts from "../components/concerts/Concerts";
import Helmet from "../components/Helmet";

function AllConcerts() {
    return (
        <React.Fragment>
            <Helmet
                title="Concerten"
                description="Bekijk onze opkomende speeldata en voorafgaande concerten."
            />

            <Container>
                <div className="text-center my-5">
                    <h1 style={{ fontSize: "4rem" }}>Second To Last</h1>
                </div>
            </Container>

            <Container className="my-5">
                <h1 className="text-center mb-3">Opkomende speeldata</h1>
                <Concerts type="upcoming"/>
            </Container>

            <Container className="my-5">
                <h1 className="text-center mb-3">Voorgaande concerten</h1>
                <Concerts type="past"/>
            </Container>

        </React.Fragment>
    )
}

export default React.memo(AllConcerts);
