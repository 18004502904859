import React, {
    useMemo
} from "react";
import {
    Container,
    Nav
} from "react-bootstrap";

import Concerts from "../components/concerts/Concerts";
import SocialIconLink from "../components/SocialIconLink";
import Helmet from "../components/Helmet";
import useWindowSize from "../hooks/WindowSize";

import home1 from "../img/home/home-1.jpg";
import home2 from "../img/home/home-2.jpg";

function FullWidthImages() {
    const windowSize = useWindowSize();
    const images = useMemo(() => {
        return [home1, home2];
    }, []);
    const height = useMemo(() => {
        return Math.min(500, windowSize.width / images.length / 3 * 2);
    }, [images, windowSize.width]);
    return (
        <div className="d-flex flex-row">
            { [home1, home2].map((imageUrl, index) => (
                <div
                    key={ index }
                    className="flex-grow-1"
                    style={{
                        backgroundImage: `url(${imageUrl})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        height
                    }}
                />
            )) }
        </div>
    );
}

function Home() {
    return (
        <React.Fragment>
            <Helmet/>

            <Container>
                <div className="text-center my-5">
                    <h4 style={{ fontSize: "3rem" }}>We are</h4>
                    <h1 style={{ fontSize: "4rem" }}>Second To Last</h1>
                </div>
            </Container>

            <FullWidthImages/>

            <Container className="text-center my-5">
                <div className="mx-auto" style={{ maxWidth: "650px" }}>
                    <h1 className="mb-3">Over Ons</h1>
                    <p>
                        Wij zijn Second To Last. Een 6 koppige pop-rock band uit het Westland. Ons repetoire bestaat uit een
                        mix van covers en eigen nummers. Ons doel is altijd om ons publiek een onvergetelijke avond te
                        bezorgen. Één om nog lang op terug te kijken.
                    </p>
                    <p>
                        Woon je in het Westland? Dan ken je ons wellicht wel, want hier zijn wij veel te vinden. Wij hebben
                        onder andere meegedaan aan de WOS Songfestival Challenge 2021, en zijn regelmatig te horen op de WOS
                        Radio. Menig zaaltje heeft ons al over de vloer gehad. Zoals; WPC Nederland 3, YMCA Scheveningen, De
                        Paap in Den Haag en nog veel meer.
                    </p>
                </div>
            </Container>

            <Container className="my-5">
                <h1 className="text-center mb-3">Opkomende speeldata</h1>
                <Concerts type="upcoming-home"/>
            </Container>

            <Container className="text-center my-5">
                <div className="mx-auto" style={{ maxWidth: "650px" }}>
                    <h1 className="mb-3">Volg Ons</h1>
                    <p>
                        Volg ons op alle socials om van alles op de hoogte te zijn!
                    </p>
                    <Nav className="d-flex justify-content-center" style={{ fontSize: "2.5rem" }}>
                        <SocialIconLink icon="instagram" link="https://instagram.com/secondtolastmusic"/>
                        <SocialIconLink icon="youtube" link="https://youtube.com/channel/UC0-LJZyMAWXqWTfL5VLLz3A"/>
                        <SocialIconLink icon="tiktok" link="https://tiktok.com/@secondtolastband"/>
                    </Nav>
                </div>
            </Container>

        </React.Fragment>
    )
}

export default React.memo(Home);
